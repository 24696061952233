import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer, EuiButton } from '@elastic/eui';
import DangerBox from 'components/Advanced_User/Alexa_Voice_Control_without_Cloud/DangerBox';
import TimeLine from 'components/Products/CommentList/HomekitAlexaList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Node-RED and Alexa",
  "path": "/Advanced_User/Alexa_Voice_Control_without_Cloud/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "A Node-RED node to control your camera via Amazon Alexa. Use voice commands to control your INSTAR IP camera without the need for an Amazon Alexa Skill - no Cloud connection required. Works directly with Alexa in your local network. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_Alexa.png",
  "social": "/images/Search/AU_SearchThumb_Alexa.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Alexa_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Node-RED and Alexa' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='A Node-RED node to control your camera via Amazon Alexa. Use voice commands to control your INSTAR IP camera without the need for an Amazon Alexa Skill - no Cloud connection required. Works directly with Alexa in your local network. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_Alexa.png' twitter='/images/Search/AU_SearchThumb_Alexa.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Alexa_Sprachsteuerung_ohne_Cloud/' locationFR='/fr/Advanced_User/Alexa_Voice_Control_without_Cloud/' crumbLabel="Alexa" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "node-red-and-alexa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-and-alexa",
        "aria-label": "node red and alexa permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED and Alexa`}</h1>
    <p>{`A Node-RED node to control things via Amazon Alexa. Use voice commands to control your INSTAR IP camera without the need for an Amazon Alexa Skill - no Cloud required. We are going to use the `}<a parentName="p" {...{
        "href": "https://flows.nodered.org/node/node-red-contrib-alexa-local"
      }}>{`node-red-contrib-alexa-local`}</a>{` to create an endpoint for Alexa to control. It was designed to emulate a Philips Hue bridge & device within local network. Amazon Echo is hardcoded to support only `}<em parentName="p">{`on/off/dimming`}</em>{` command via this route. Any other type of support has to go through the Alexa Skills route (cloud-based).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer size="s" mdxType="EuiSpacer" />
    <h2 {...{
      "id": "node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red",
        "aria-label": "node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED`}</h2>
    <p>{`We already installed Node-RED `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`under Windows`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Node-RED_Setup/"
      }}>{`on a Raspberry Pi`}</a>{`. Open the admin panel and go to the `}<strong parentName="p">{`Manage Palette`}</strong>{` menu to install the `}<strong parentName="p">{`alexa-local`}</strong>{` node:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/e9beb/NodeRED_Alexa_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADMElEQVQozzWQSUwTcRSH50KCpqXOfzpDmRZQqhWkRaoVQWjL4BI3jAcTLy5x46AXOOtB45EoJUgLXty3gGBUUBFE2o4ULUuppQudAboPoWNtaQ0HHdNWv/wOLy/53kt+0MlDR06dOn3uwvnmlpbLV65cbGo6fPSYWkto6hu0xF5NOulBrSVq1Zo6TX1V9Z6tcmW1YntN6TaI0GrramvPnD2rN3R1duoNXd2323R3OvV6Q1c6Xd3pvd5w78HDnt6XPb29N67fVNYfv0E06CpVUI1KtU+jJQhCoVBsyiCVShEE4fF4fD4/LwOPx8vPz1epVEqlsqCgAACAY1iJRAIlEvFE/Cf3J00qlYqxLPf7d3Nzy07VTo1GXf4fmUy2raxMWiLNXt8klUqKiyFfIOimFxj2RygSCUci0z7/VDCsJhqEABZL8HXrc3Nzc3Nycvh8frlcLhaLYRhGMgAAII/X//7jxLuhcaeLDi8zRnphmKJPXGrS7t1/+EBj9a663VXVBEHI5fLCoiIcxxEEEWZAEARibNOup49czx4HZqaZKOvw+sxWm8XhWfRR40NvLINv7ZPWxOpqa2urQCAoKy3FMAwA8O/zArtI+knzkpliqFAgPDlqtQyQ38nvLGVjZwaZr31e+0RiNXXt6lVJYeH2iootmzejKJq1IYZhXE6Xy+kOBUOhcIQctoz2fbKO2ULOcXrU4B6+G/TO/eE4nU5XIBJtLCraIBAgAAgBEMAwFKKDDtI+a5xZ9jOxeNxqnrR8+OKcdDO+KY+lI+jsX1mJchz34nabEsUOKip24eJKIVqOYo2YCIqueBYXPlDeQTZKJ3+tBWNsJBZbSSWTqV/Jn2txNhXNyE9ab1UIUaK0rDJfJAeIDBEeFGJQJDI3O/vSan3OMO5YIjXknu+32V873Xa31/jG9KlvjPYschzX3tHB4/NxHN8Aw9m68wCAKMprMhmNpjG/3xdfTZrm5gcmpkYcnlmP62Pfa2P/u6V5Ki3rdGKxWF2n3qFUZqsGMAzRNE2SpNlsDgQCiXji68g346vPMybbMm1bMj/1jtwP04603N4uRFGZbItIJAIg68J/Ac/7o2JKZh1gAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5327b4c660ff5dc972ea6dc5da1554a0/e4706/NodeRED_Alexa_01.avif 230w", "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/d1af7/NodeRED_Alexa_01.avif 460w", "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/b6582/NodeRED_Alexa_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5327b4c660ff5dc972ea6dc5da1554a0/a0b58/NodeRED_Alexa_01.webp 230w", "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/bc10c/NodeRED_Alexa_01.webp 460w", "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/87ca7/NodeRED_Alexa_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5327b4c660ff5dc972ea6dc5da1554a0/81c8e/NodeRED_Alexa_01.png 230w", "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/08a84/NodeRED_Alexa_01.png 460w", "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/e9beb/NodeRED_Alexa_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5327b4c660ff5dc972ea6dc5da1554a0/e9beb/NodeRED_Alexa_01.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/e9beb/NodeRED_Alexa_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACPklEQVQozz3QUU/TUBQA4P4ZELoOfwRBfFNkSdcOmUIirxKMvvljSJRtVYGtIhTp7NquXbcGNAG2Cm5AKK6s5bb3diOUMV0JfDm5OQ/n5JxzMfLJ04lYjKJpMh6feDY5OvaIiD7EI9EIMRIhRnAiikfCwAkcJyJE9MEQPjA4PIwTA4NDWGxykqYoZnHxO/t1LZv7vLz8cSmVSmdSDJPKMOkMk2Y+pTPMapZdWc1l2eybt+9Hx2IkGRsff4zNTE/Pzsy+TL6gSJKKxymKommKpmm6n9CJqSmKokiSnJubW1hYeD0/P51MJqaeJxL9CkwSJX6L393drdVq1XuGUTWM/Wq1ur+vKgrDMCzLchy3vr7OsuxyKJfLYRBCz/MQQv49hGCz2fHcw9Sq+urdwc6vH2KB2+AEQdA0TVGUYrF4+2LNZtOxnbMzs16vW5Zl2XbL+F1fyTZLGizpF19Yo6RxPF+U5YIgCFtbAs/LkqSqqiiK2MnxSavVOj8/v7gFALAsq2bYR8d/Dw+RaRp7e3w+Xy6XpZCiKJqmVSoVWZb7kyGECCEAgB1yAOgEV1fdrt/pOAA0Go3NzU2e5zluI5/Pq6paDEmShJ2emkFwHQQBAAC4wHFsx7YRane73W4Q3PR6f46O8jwvimKhUJBlWb3Tb4YQAgDa7TYAwDTN2909CH3f9xC6NA4aOz8VTdN1vVLRK3d0Xe9/GHDdsNmHELmu2277rutdBUGv17u8ufE/LNXXvqnb2+VSSQuV7/y/+R8ls9uv/K4ZIQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f83dee37bb22eeec053cc0f5a9fc761/e4706/NodeRED_Alexa_02.avif 230w", "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/d1af7/NodeRED_Alexa_02.avif 460w", "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/b6582/NodeRED_Alexa_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f83dee37bb22eeec053cc0f5a9fc761/a0b58/NodeRED_Alexa_02.webp 230w", "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/bc10c/NodeRED_Alexa_02.webp 460w", "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/87ca7/NodeRED_Alexa_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f83dee37bb22eeec053cc0f5a9fc761/81c8e/NodeRED_Alexa_02.png 230w", "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/08a84/NodeRED_Alexa_02.png 460w", "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/e9beb/NodeRED_Alexa_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f83dee37bb22eeec053cc0f5a9fc761/e9beb/NodeRED_Alexa_02.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should see a new `}<strong parentName="p">{`Input Node`}</strong>{` called `}<strong parentName="p">{`alexa local`}</strong>{`. Drag it onto the working area and double-click it. Type in a function name - e.g. `}<strong parentName="p">{`Area One`}</strong>{`. This is the name that Alexa will use to identify the device. And the name you have to use to select it - so choose its name wisely.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/e9beb/NodeRED_Alexa_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAtklEQVQY043LOQrCQBQAUE/rAbyGR7DS1rWNYONSiKCNQhJMkGQmk4wyi2Ag88f/raxU8PWvdVn2z4se280edY34JKLnfxCxNQrWg0kw3+zt3TLGKimbpgEA5xy8ee+/5844aPdH3WBljYrjWAhhjDFaG62VUrfrVSn1Mw932+lhv07PleCn4ylJ0zAMkySJoohzTkSICADfc5llUNfonNZaSilEyRkviiLPc2stInrvAQA/ENELf64XpSUFugMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/e4706/NodeRED_Alexa_03.avif 230w", "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/d1af7/NodeRED_Alexa_03.avif 460w", "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/b6582/NodeRED_Alexa_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/a0b58/NodeRED_Alexa_03.webp 230w", "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/bc10c/NodeRED_Alexa_03.webp 460w", "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/87ca7/NodeRED_Alexa_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/81c8e/NodeRED_Alexa_03.png 230w", "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/08a84/NodeRED_Alexa_03.png 460w", "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/e9beb/NodeRED_Alexa_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8784cc4b30d5aa7e44fc6bda08c2c8f8/e9beb/NodeRED_Alexa_03.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f1523d36cb425597388cbd7cdf4fba63/e9beb/NodeRED_Alexa_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABLElEQVQY03XNT0/CMBjH8b1EpwwILBo8cvBNePHgRV+Ab8aLMWUz/JEBWzsU0G3A1q1du7WsRtGERP3kd/0+j3baapumaRiGruu1X4xG3WjUj45186R2dd697nQvjFbTbLeazXrnTAMA2LYNABgMB77vox8QQojQbPQ8G41dz3uyrPfFchtGvgediTNxHHviaGmWYYzTNCWE0EOEMCmsy9v+zV2hqpzSnOWMM0ppkiSUkNfNWguC4PPJfB5TmlGafh36lmVJtI7DqChLpVR1QCm1jDdakuCyqorFW3j/UERboZSQldip/WSlpJRCCHVgH6+SWOMcsxJnK4SHNl2vWJExjvfLOeZFKiUXQv4dR6HrucCFlvfSd2EPQgvCHkK2j+zp9DEIxrsd+S/+AJuQYwx15inwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1523d36cb425597388cbd7cdf4fba63/e4706/NodeRED_Alexa_04.avif 230w", "/en/static/f1523d36cb425597388cbd7cdf4fba63/d1af7/NodeRED_Alexa_04.avif 460w", "/en/static/f1523d36cb425597388cbd7cdf4fba63/b6582/NodeRED_Alexa_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f1523d36cb425597388cbd7cdf4fba63/a0b58/NodeRED_Alexa_04.webp 230w", "/en/static/f1523d36cb425597388cbd7cdf4fba63/bc10c/NodeRED_Alexa_04.webp 460w", "/en/static/f1523d36cb425597388cbd7cdf4fba63/87ca7/NodeRED_Alexa_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1523d36cb425597388cbd7cdf4fba63/81c8e/NodeRED_Alexa_04.png 230w", "/en/static/f1523d36cb425597388cbd7cdf4fba63/08a84/NodeRED_Alexa_04.png 460w", "/en/static/f1523d36cb425597388cbd7cdf4fba63/e9beb/NodeRED_Alexa_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f1523d36cb425597388cbd7cdf4fba63/e9beb/NodeRED_Alexa_04.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Connect a `}<strong parentName="p">{`Debug Node`}</strong>{` to its output and `}<strong parentName="p">{`Deploy`}</strong>{` the flow. Then ask `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"Alexa, discover devices"`}</code>{` - Alexa should reply that it discovered `}<strong parentName="p">{`Area One`}</strong>{`.`}</p>
    <p>{`You can test it by issuing the following commands:`}</p>
    <ul>
      <li parentName="ul">{`"Alexa, turn off Area One"`}</li>
      <li parentName="ul">{`"Alexa, switch on Area One"`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/e9beb/NodeRED_Alexa_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABb0lEQVQY023Lz0vCUADA8f0R0bY0umSEBdVBugj9AR37fzr3D3SOQOgSFEFGERVGYqCZmZZYm5uwtvfc9t729vbrbVbiJehz+Z6+3ObaeqFQyOfzmUxGnBNneZ4XBFEUeYHnBV4QBHFOnM9mZwR+Z3ljr7i9u75VXMgtr64sLeY4CIBHiOd5rus6NrIB8F0HY2xCy4SWi4mDXQDAcKhKkuRRSgjRdV3TNHU45CRJthGyEQaGfvP6vH99efBw35XfzqunJ3fH5epZq9fUNA0hZNs2whghZJomhBBjzF1VX8qVxlPnExp6rT+4aNRrnx9fI70jtVu9Zld+VbSBoijIspBpEsfxfd/zvCiO0jTleqWjzmFJvasYEI7TdDwxzUQURaqqYjgiGFNKgyCglDLGfufHQaXav33X2sAAMYujOI4ZS5IknmAJ84NAVRQ8GmHTooSEYej7/nRutJv1drMvfxiGkfyHMYYQYlE4/utn/gZr+FLasYXGXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/e4706/NodeRED_Alexa_05.avif 230w", "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/d1af7/NodeRED_Alexa_05.avif 460w", "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/b6582/NodeRED_Alexa_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/a0b58/NodeRED_Alexa_05.webp 230w", "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/bc10c/NodeRED_Alexa_05.webp 460w", "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/87ca7/NodeRED_Alexa_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/81c8e/NodeRED_Alexa_05.png 230w", "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/08a84/NodeRED_Alexa_05.png 460w", "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/e9beb/NodeRED_Alexa_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b65f478d43c3a2d4f65d7fef038ddb2f/e9beb/NodeRED_Alexa_05.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will see that the corresponding payloads - `}<strong parentName="p">{`on`}</strong>{` or `}<strong parentName="p">{`off`}</strong>{` are received by the Alexa Local Node. To be able to send percentage values, we will have to add a little Function Node to our flow. It needs to be connected to the output of the Alexa Local Node and listens for a `}<strong parentName="p">{`Brightness`}</strong>{` value to be send with the Alexa command. Remember that we are exploiting a function here that is meant to be used by local light switches on your network?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/2a195/NodeRED_Alexa_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWUlEQVQoz33S/WvTQBgH8PxlwqSdsBU2Kf5Z6kj1p7mxde6luNq1e+vWtctWYWD3gxZxv+icNHeXJr3k8nZJ7i6JpGUwtfrh4eHh4AvHwyMV5uaKCwtPFxeLxeL8/Pzs7OyTsXw+n/tLfiyXyz2emSkUCtLL5y9ey/KrUunN8rIsy0tLS7Isl0qllZXVcrm8Ps3G1tZm9d1WpSJdX/eurq56vR4EAICsQQQRQpRSIQT/HWOMJ7GPdOPjJwcgSVHODw8OFOUcAOC6ru/7lFLf922bmKZpPUAIwYZBwuBme6/x6NmX8q7UvuhWj9vbjea32zsI1K83N/1+v/+5r2kaIVPyxLZNjDHUsDGSOs3TvbXN2urG4O6n67omNh3bJoSEYcSnYkwIEadpGEXS5UmztbHeXFsFdz+QbugjnI7FSSri6cXjZLIO6exDu3pZq3R21SF0/RBbjohTxhMmxvVwuJ/9gDmOE0VM6na7rXareXIMIXAcByIURSxOkjiTdTGZMmk8eY5jznkYhpLSuTiuN49qRwiioT68/X5rDEcjjAEAqqoOBiqEwDAMy7QwxjYh3pjrupRS6eJcOakfHr6vQxUEYUCI7dqUBiHGIxUAXTcQQpqm6YZhOw7jPL0XRZGU/bq902q9RdqAUmpZVpqkXHDGspOIwigMQyF4wlmaJOkDk/DZ6elOo7GG0CCggYlNj/gjw3Rc16fUtm3P80Qs4sDjUfRnuNNp1WqVanUbADWgASGWa/kO8TgX6X9lYUVR9vcP6vUGhND3Pd0wGBOc8fEhpMlky/8I/wJAuwAriLI98wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/e4706/NodeRED_Alexa_06.avif 230w", "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/d1af7/NodeRED_Alexa_06.avif 460w", "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/e25ad/NodeRED_Alexa_06.avif 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/a0b58/NodeRED_Alexa_06.webp 230w", "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/bc10c/NodeRED_Alexa_06.webp 460w", "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/cf77e/NodeRED_Alexa_06.webp 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/81c8e/NodeRED_Alexa_06.png 230w", "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/08a84/NodeRED_Alexa_06.png 460w", "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/2a195/NodeRED_Alexa_06.png 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/54202e4b6e72d4682d7aa7e2ca0154cc/2a195/NodeRED_Alexa_06.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The function leaves the payload intact - if the incoming command is of the `}<strong parentName="p">{`ON/OFF`}</strong>{` nature. But if a brightness value is send, it will set this to become the payload of the message:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`payload `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`bri `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` payload`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`on_off_command `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` payload`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{` msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`on_off_command `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` a `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`You can now use the following example commands:`}</p>
    <ul>
      <li parentName="ul">{`"Alexa, set Red Area Sensitivity to 40%"`}</li>
      <li parentName="ul">{`"Alexa, set Red Area Sensitivity to 30"`}</li>
      <li parentName="ul">{`"Alexa, increase Red Area Sensitivity"`}</li>
      <li parentName="ul">{`"Alexa, decrease Red Area Sensitivity"`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6defe0225338dc8a6b667de21769648/e9beb/NodeRED_Alexa_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABrUlEQVQoz12QXW/TMBSG+9uHtJuJK+42NgGqmNiX+NgGHZM6RLtmZKjQ0a7tmrSIOB+NEyd2kqZ24oOSjnXlkW+O7Pc9j1z5PNSOu7dve/2B43S1jvLr8uqmpfYU7c8onacAEMcxQogxxmiB67oYYw/jIAgrz+qfnhzX1s7rHYQa3z++ONl++WF79+xVo/0liiMAoJTqumY7jmlbbkmSJJxz3/crh+2rHUWtqtebitqYGAAg4Z4sy0ACY2ys69hxHNOcRfHiSkoZhmFlvdZcP61tnNWfnl+cdG4oDXLI5bKhDI/HLEik4Dydy5I8z4uwN/rhq+/M5n6EhsQhQ2XYV/qj69FAGfSaPWNgMMYmk7E9nZq25RMS0lAIcb858pCnt1H/G3HNn7fdrerW853N13vVo/cHra8t3/LTeepOp65luaaVMLaiLf8NAPDbmhw19g4v3uzXd2vqqWbcAYAQwvP9KEpBSs55nudL7SwTxRGi+J5VFo84Fx7GyESGYRBCKKX8QTt/RLaoKSlLMyllsRljF5mebSd0qR0EwUr4QenxKAQnJJjNsv+kKKV/ARosI03jY21QAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6defe0225338dc8a6b667de21769648/e4706/NodeRED_Alexa_07.avif 230w", "/en/static/c6defe0225338dc8a6b667de21769648/d1af7/NodeRED_Alexa_07.avif 460w", "/en/static/c6defe0225338dc8a6b667de21769648/b6582/NodeRED_Alexa_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6defe0225338dc8a6b667de21769648/a0b58/NodeRED_Alexa_07.webp 230w", "/en/static/c6defe0225338dc8a6b667de21769648/bc10c/NodeRED_Alexa_07.webp 460w", "/en/static/c6defe0225338dc8a6b667de21769648/87ca7/NodeRED_Alexa_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6defe0225338dc8a6b667de21769648/81c8e/NodeRED_Alexa_07.png 230w", "/en/static/c6defe0225338dc8a6b667de21769648/08a84/NodeRED_Alexa_07.png 460w", "/en/static/c6defe0225338dc8a6b667de21769648/e9beb/NodeRED_Alexa_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6defe0225338dc8a6b667de21769648/e9beb/NodeRED_Alexa_07.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will see the % values transmitted in the Debug Node.`}</p>
    <h3 {...{
      "id": "add-the-mqtt-input-to-an-existing-flow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-the-mqtt-input-to-an-existing-flow",
        "aria-label": "add the mqtt input to an existing flow permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add the MQTT input to an existing Flow`}</h3>
    <p>{`In the following sequence we are using the input from Alexa to switch `}<strong parentName="p">{`Alarm Areas`}</strong>{` on our cameras - we already used the same sequence for the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/XiaoMi_Home_Zigbee2MQTT/"
      }}>{`Zigbee Integration`}</a>{` (the complete flow can be found in the `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Home_Assistant/"
      }}>{`Home Assistant Tutorial`}</a>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/e9beb/NodeRED_Alexa_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABI0lEQVQY022My07CQABF+/+/4NqouDFgbDASdGGjGHkIBZTOQDvTeZbpTGsfthSMbFzoyV2dmxxrickQBETSLe2H4T1AfSYnMdUUUi1NKmIOiKaRIS+GTxR/joWjthRjkmWfVgDJxyLgBEXwagqeTkfOwH9vyjrLyiaKH9v2eavNenbeP6MP3eXNpbht5ZJlZVlXlWXSVBlT73aHv9R1wpnivJK8icSXVDmVBWFNVf28+4PlbPw7D0AqhC/wCiumjv64/W9n/0/7YHEZB0waZfJrW6EwK1RRkCLGOV2WGqtoGG+nxuDE+JxTjFCaJCRERmvFlIXmAXwFoYeJO0u00WpD0YC5TjTqRDMbzE9CvwNhb73urlaL8fhN8tAbX1Df9Xz2DYydRWBDapmYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/e4706/NodeRED_Alexa_08.avif 230w", "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/d1af7/NodeRED_Alexa_08.avif 460w", "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/b6582/NodeRED_Alexa_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/a0b58/NodeRED_Alexa_08.webp 230w", "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/bc10c/NodeRED_Alexa_08.webp 460w", "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/87ca7/NodeRED_Alexa_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/81c8e/NodeRED_Alexa_08.png 230w", "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/08a84/NodeRED_Alexa_08.png 460w", "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/e9beb/NodeRED_Alexa_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/edd05c3d6889c41bf46ac3f9937b2ccb/e9beb/NodeRED_Alexa_08.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can copy the following JSON code and import it into Node-RED (`}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/#using-node-red-to-communicate-with-your-camera"
      }}>{`How do I import flows to Node-RED?`}</a>{`):`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiButton color="primary" fill href="https://wiki.instar.com/en/Node-RED_Flows/nodered_flow_alexa_sprachsteuerung_ohne_cloud.json" mdxType="EuiButton">
    Download Flow (JSON)
    </EuiButton>
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiButton color="primary" fill href="https://wiki.instar.com/en/Node-RED_Flows/nodered_flow_alexa_sprachsteuerung_ohne_cloud.json" mdxType="EuiButton">
    Download Flow (JSON)
    </EuiButton>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`All we needed to add is a `}<strong parentName="p">{`String Node`}</strong>{` (this node type `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/#the-node-red-admin-panel"
      }}>{`has to be installed manually`}</a>{` - it is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red-contrib-string`}</code>{`) that searches for the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`on`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`off`}</code>{` and replaces them with something that we need to trigger the Flow below - in this case we need a JSON expression that either sets a value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val" : "on"}`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val" : "off"}`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89ae6b1a2a602dc2a727c993847da0af/e9beb/NodeRED_Alexa_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChUlEQVQoz2WSeU/bMBjG8yn39z7XxLQhhNCmVUNrBUMt0IUcvXK2pYPSM+lB07SJc9uxPSUZG8dPjy3rlR+/r/2aaeQIgsDzPMuyvweDyXh8P7yfzqbAew0Aecz3/TD0goCRcyRJkmWp1W7NZrMgDP3gL2/dAYQrWau/e393eMKoWlfN0DWtqyj6fG5sNo+T6XQyHluW5eeGf8rwgGNZ627fns0ZrnrEi1X25qTOHl9WP07GWhjFruvk5YVxguIE5jP6v0ZpSmmKMdOoVzo/vjVOD1vfP3PVb3NjBAAI/IBSGkZJgnCKKUpfCGYzoZQy0lVdPPhwfnx0c3DAlU6niyVKUX403e73G3vn+kFKCKb0pXKz2ulotZpWq+m1WkcQzMXCNk3bMLAL4N6J7R1y9hh4r0QAIHHMKIqi6Lqi66qud1TVvB2gu3tnNHKHD2ixRIslNBd4tX4u8riB01nY6+dmWVZkWZXljqqYvT6xthYADgDZq2TKKszGM5IkiYYjJu+TquVImmb2+2RjxSi7OMGYEJIi5AGQxDFMEpymRTDyvGj4wLTbTVEUeZ5viI22LBvdLt3aWSpCMMnyQQgdZ1/8GYhgmqYpxjCKMnO9Xi2XK6VS6fzs7Iplx5JMbdt23e12C5+gb0BxnJk57vri4qJSKdeqVU4UR50W3lhbx/E9L0v+BCEvbh0WZUuSpOldvTfQ9b6i60avR+0dzesu9kGErN3OjyI/DBHGRZ+TOI4eRsz19ZV4c/7r5yfh6qvQas00nZoLEobYBUVL06zhO+Q4cO8g103zeGJZYf+W4XiuKVxyl19EttxoNo3JJJpMk/mcrFZ4uSxElqtCuNBqjeYGWj/+AYS9Yb6Vbsj0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89ae6b1a2a602dc2a727c993847da0af/e4706/NodeRED_Alexa_09.avif 230w", "/en/static/89ae6b1a2a602dc2a727c993847da0af/d1af7/NodeRED_Alexa_09.avif 460w", "/en/static/89ae6b1a2a602dc2a727c993847da0af/b6582/NodeRED_Alexa_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89ae6b1a2a602dc2a727c993847da0af/a0b58/NodeRED_Alexa_09.webp 230w", "/en/static/89ae6b1a2a602dc2a727c993847da0af/bc10c/NodeRED_Alexa_09.webp 460w", "/en/static/89ae6b1a2a602dc2a727c993847da0af/87ca7/NodeRED_Alexa_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89ae6b1a2a602dc2a727c993847da0af/81c8e/NodeRED_Alexa_09.png 230w", "/en/static/89ae6b1a2a602dc2a727c993847da0af/08a84/NodeRED_Alexa_09.png 460w", "/en/static/89ae6b1a2a602dc2a727c993847da0af/e9beb/NodeRED_Alexa_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89ae6b1a2a602dc2a727c993847da0af/e9beb/NodeRED_Alexa_09.png",
              "alt": "Node-RED and Alexa",
              "title": "Node-RED and Alexa",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We then add a `}<strong parentName="p">{`Link Node`}</strong>{` to plug it in to all 4 sequences for the for the 4 alarm areas and `}<strong parentName="p">{`Deploy`}</strong>{`:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><img parentName="p" {...{
        "src": "/en/27130dea74be3d2da020bfc37f392942/nodered_01.gif",
        "alt": "Node-RED and Alexa"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Now we can activate all areas with the command: `}<em parentName="p">{`Alexa, activate Red Test`}</em>{`. And deactivate them by saying `}<em parentName="p">{`Alexa, switch off Red Test`}</em>{`:`}</p>
    <h2 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h2>
    <p>{`If you have installed a firewall on the computer on which Node-RED is running, the following ports must be released for the Alexa services:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Output TCP: *, 80, 8080, 443, 40317, 67, 68`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Output UDP: *, 53, 123, 40317, 49317, 33434, 1900, 5000, 5353`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Input TCP: 8080, 443, 40317`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Input UDP: 53, 67, 68, 1900, 50000, 5353, 33434, 49317, 40317`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      